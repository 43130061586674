<template>
    <v-container fluid>
        <v-form @submit.prevent="saveCompanyClinic">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <div class="d-flex pb-2">
                    <div style="width: 100%">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-subheader class="headline pl-0">{{ heading }}</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="6" class="pt-1">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <v-row>
                    <v-col cols="12" sm="3" md="2" class="mb-2 px-sm-6 text-center">
                        <make-logo v-model="logo" :aspect-ratio="(1/1)"/>
                    </v-col>
                    <v-col cols="12" sm="9" md="10">
                        <v-row>

                            <v-col class="py-0" cols="12" sm="8">
                                <ValidationProvider ref="company" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="company" type="text" :error-messages="errors"
                                                  :error="!valid" :disabled="loading"
                                                  prepend-icon="mdi-rename-box"
                                                  :label="$t('company_name')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="name_director" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name_director" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('name_director')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="8">
                                <ValidationProvider ref="name_legal" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name_legal" type="text" :error-messages="errors"
                                                  :error="!valid" :disabled="loading"
                                                  prepend-icon="mdi-rename-box"
                                                  :label="$t('company_name_legal')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="name_responsible" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name_responsible" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('name_responsible')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="bin_iin" rules="required|numeric|length:12|bin_iin"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="bin_iin" type="text" :error-messages="errors"
                                          :error="!valid" :disabled="loading"
                                          prepend-icon="mdi-numeric" :label="$t('bin_iin')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable autocomplete="off">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="phone" rules="required|phone"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="phoneRaw" type="tel"
                                          v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                          :error-messages="errors" :error="!valid"
                                          :disabled="loading" prepend-icon="mdi-phone"
                                          :label="$t('phone')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary"
                                          clearable>
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="email" rules="email|min:5|max:50" v-slot="{ errors, valid }">
                            <v-text-field v-model="email" type="email" :error-messages="errors"
                                          :disabled="loading" prepend-icon="mdi-email" label="Email"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable>
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="checking_account" rules="required|length_char:20"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="checking_account" type="text" :error-messages="errors"
                                          :error="!valid" :disabled="loading"
                                          prepend-icon="mdi-bash"
                                          :label="$t('checking_account')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable autocomplete="off">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="contract_number" rules="required|min:1|max:255"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="contract_number" type="text" :error-messages="errors"
                                          :error="!valid" :disabled="loading"
                                          prepend-icon="mdi-shield-star"
                                          :label="$t('contract_number')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable autocomplete="off">
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="4">
                        <v-dialog ref="dateDialogContract" v-model="dateDialogContract" width="290px">
                            <v-date-picker ref="pickerDateContract" v-model="date_contract"
                                           :locale="lang" @change="dateDialogContract = false">
                            </v-date-picker>
                        </v-dialog>
                        <ValidationProvider ref="dateContract" rules="required|date_format"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="date_contract" :error-messages="errors"
                                          :error="!valid" :disabled="loading"
                                          :label="$t('date_contract')"
                                          prepend-icon="mdi-calendar" color="primary" readonly
                                          @click.stop="dateDialogContract = !dateDialogContract"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          clearable @click:clear="date_contract = null"></v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="12">
                        <ValidationProvider ref="city" rules="required"
                                            v-slot="{ errors, valid }">
                            <v-select v-model="city" :error="!valid" :error-messages="errors"
                                      :disabled="loading" :items="cityItems"
                                      prepend-icon="mdi-flag-checkered" color="primary"
                                      item-text="name" item-value="id"
                                      outlined
                                      rounded
                                      dense
                                      class="rounded-lg"
                                      :label="$t('city')">
                            </v-select>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="6">
                        <ValidationProvider ref="address_legal" rules="required|min:3|max:255"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="address_legal" type="text" :error-messages="errors"
                                          :error="!valid" :disabled="loading"
                                          prepend-icon="mdi-map-marker-radius"
                                          :label="$t('address_legal')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable>
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="6">
                        <ValidationProvider ref="address_actually" rules="min:3|max:255"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="address_actually" type="text"
                                          :error-messages="errors"
                                          :disabled="loading" prepend-icon="mdi-map-marker-radius"
                                          :label="$t('address_actually')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable>
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col v-if="can(['administrator'])" cols="12">
                        <ValidationProvider ref="notes" rules="min:3|max:65535"
                                            v-slot="{ errors, valid }">
                            <v-textarea v-model="notes" type="text" :error-messages="errors"
                                        :disabled="loading" :label="$t('notes')" rows="5"
                                        color="primary" auto-grow clearable outlined>
                            </v-textarea>
                        </ValidationProvider>
                    </v-col>


                </v-row>
                <v-row>
                    <v-col cols="12" class="d-flex">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2 mt-5"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>

                    </v-col>
                </v-row>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import {mapGetters} from "vuex"
import debounce from "lodash/debounce"
import MakeLogo from '../components/MakeLogo.vue'

export default {
    name: 'CompanyClinicForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeLogo
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            tab: 0,
            language: null,
            exist_translations: {},
            all_translations: true,
            progress: 0,
            create: false,
            phoneRaw: null,
            company: null,
            name_legal: null,
            name_director: null,
            name_responsible: null,
            address_actually: null,
            address_legal: null,
            contract_number: null,
            checking_account: null,
            email: null,
            bin_iin: null,
            logo: null,
            notes: null,
            loading: false,
            loadingShops: false,
            loadingPartners: false,
            loadingBrands: false,
            date_contract: null,
            dateDialogContract: false,
            city: null,
            cityItems: [],


        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'phoneMask']),
        languages() {
            return this.listLanguages
        },
        phone: function () {
            return this.changePhone(this.phoneRaw)
        }
    },
    async mounted() {
        this.language = this.languages[this.tab]
        await this.getCities()
        await this.checkCreate()
    },
    methods: {
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        checkCreate() {

            this.heading = this.$t('company_editing');
            this.getCompanyClinic(0);

        },
        async getCities() {
            var _this = this
            let params = {}
            params.list = 'all'
            await this.$http.get(`admin/city`, {
                    params: params,
                }
            )
                .then(res => {
                    this.cityItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_cities'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },
        async getCompanyClinic(id) {
            var _this = this;
            this.progress = 0
            this.loading = true
            this.id = id
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/company_clinic/${id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.chain = res.body.data.chain_id
                    this.company = res.body.data.name
                    this.city = res.body.data.city.id
                    this.name_legal = res.body.data.name_legal
                    this.name_director = res.body.data.name_director
                    this.name_responsible = res.body.data.name_responsible
                    this.address_actually = res.body.data.address_actually
                    this.address_legal = res.body.data.address_legal
                    this.contract_number = res.body.data.contract_number
                    this.checking_account = res.body.data.checking_account
                    this.phoneRaw = res.body.data.phone
                    this.bin_iin = res.body.data.bin_iin
                    this.email = res.body.data.email
                    this.date_contract = res.body.data.date_contract
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                    this.notes = res.body.data.notes
                    this.logo = res.body.data.logo
                    this.show_languages = res.body.data.languages
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_company'));
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveCompanyClinic() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.company) {
                formData.append('company', this.company)
            }
            if (this.name_legal) {
                formData.append('name_legal', this.name_legal)
            }
            if (this.name_director) {
                formData.append('name_director', this.name_director)
            }
            if (this.name_responsible) {
                formData.append('name_responsible', this.name_responsible)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }
            if (this.email) {
                formData.append('email', this.email)
            }
            if (this.bin_iin) {
                formData.append('bin_iin', this.bin_iin)
            }
            if (this.address_actually) {
                formData.append('address_actually', this.address_actually)
            }
            if (this.address_legal) {
                formData.append('address_legal', this.address_legal)
            }
            if (this.contract_number) {
                formData.append('contract_number', this.contract_number)
            }
            if (this.checking_account) {
                formData.append('checking_account', this.checking_account)
            }
            if (this.date_contract) {
                formData.append('date_contract', this.date_contract)
            }


            if (this.notes) {
                formData.append('notes', this.notes)
            }
            if (this.chain) {
                formData.append('chain', this.chain)
            }

            if (this.city) {
                if (this.city.id) {
                    formData.append('city', this.city.id)
                } else {
                    formData.append('city', this.city)
                }
            }


            if (this.logo) {
                if (this.logo.length > 250) {
                    var mimeTypeLogo = this.getMimeType(this.logo)
                    var blobLogo = this.dataURL64toBlob(this.logo)
                    if (mimeTypeLogo && blobLogo) {
                        formData.append('logo', blobLogo, mimeTypeLogo)
                    }
                } else {
                    formData.append('logo', this.logo)
                }
            }

            // Save
            await this.$http
                .put(`admin/company_clinic/0`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('company_has_been_updated'));
                    if (res && res.body && res.body.data && res.body.data.exist_translations) {
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('company_has_not_been_updated'));
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        }
    }
}
</script>
